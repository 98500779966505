<template>
  <div class="list-item-content">
    <div
      class="list-title-container-container"
      @click="$emit('titleClick')"
      :class="onCLick ? 'clickable' : ''"
    >
      <div
        class="list-title-container"
        :class="withSeparator ? 'with-separator' : ''"
        @click="onCLick"
      >
        <div class="icon-title-container">
          <v-icon class="material-icons item-icon" v-if="icon">{{
            icon
          }}</v-icon>
          <div class="title-description-container">
            <p class="title">{{ title }}</p>
            <p v-if="description" class="description">{{ description }}</p>
          </div>
        </div>
        <v-icon v-if="withArrow" class="material-icons arrow-icon">
          keyboard_arrow_right</v-icon
        >
        <input
          v-else-if="withCheckbox"
          type="checkbox"
          class="checkbox"
          :checked="isOn"
          @change="onCheckboxChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: undefined
    },
    withSeparator: {
      type: Boolean,
      required: false,
      default: true
    },
    withArrow: {
      type: Boolean,
      required: false,
      default: true
    },
    withCheckbox: {
      type: Boolean,
      required: false,
      default: false
    },
    isOn: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: false,
      default: undefined
    },
    onCLick: Function
  },
  data() {
    return {};
  },
  methods: {
    onCheckboxChange(event) {
      this.$emit("update:isOn", event.target.checked);
      this.$emit("checkboxChanged");
    }
  },
  created() {}
};
</script>

<style scoped>
.list-title-container-container {
  text-decoration: none;
  color: inherit;
  padding: 0.1em;
  padding-left: 2em;
  padding-right: 1em;
  transition: background-color 0.3s ease;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list-title-container-container.clickable {
  cursor: pointer;
}

.list-title-container-container.clickable:hover {
  background-color: var(--primary-lower);
}

.list-title-container {
  padding-left: 1em;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.icon-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}

.title-description-container {
  display: flex;
  flex-direction: column;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  gap: 0.2em;
}

.title {
  font-size: 1em;
  margin: 0;
}

.description {
  font-size: 0.7em;
  margin: 0;
}

.checkbox {
  cursor: pointer;
}

.with-separator {
  border-bottom: solid 0.1em rgba(0, 0, 0, 0.1);
}

.arrow-icon {
  font-size: 1.5em;
}

.item-icon {
  font-size: 1.5em;
  color: var(--primary);
}
</style>
