<template>
  <div class="main-screen-container">
    <div class="main-screen-tab-bar">
      <div>
        <div class="main-screen-logo-container">
          <img
            alt="Kowander logo"
            src="@/assets/logo-with-no-text.png"
            class="main-screen-logo"
          />
          <p class="main-screen-title">Kowander</p>
        </div>

        <div class="main-screen-list">
          <TabItem
            v-for="(item, index) in items"
            :isSelected="$route.path.startsWith(item.path)"
            :key="index"
            :title="item.title"
            :onClick="item.onClick"
          />
        </div>
      </div>
      <TabItem
        class="logout"
        key="logout"
        :title="$i18n.t('logout')"
        :onClick="logout"
      />
    </div>
    <div class="main-screen-content-container">
      <NavigationBar v-bind="$props" />
      <PulseLoader v-if="isLoading" size="1em" class="loader" />
      <div class="main-screen-slot-container">
        <slot v-if="!isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import TabItem from "@/views/components/TabItem.vue";
import router from "@/router";
import NavigationBar from "@/views/components/NavigationBar.vue";
import { startListeningToNotifications } from "@/notifications";
export default {
  name: "MainScreen",
  components: {
    TabItem,
    NavigationBar
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: true
    },
    ...NavigationBar.props
  },
  data() {
    return {
      items: [
        {
          title: this.$i18n.t("home-tab"),
          path: "/home",
          onClick: () => {
            router.push("/home");
          }
        },
        {
          title: this.$i18n.t("profile-tab"),
          path: "/myprofile",
          onClick: () => {
            router.push("/myprofile");
          }
        },
        {
          title: this.$i18n.t("settings-tab"),
          path: "/settings",
          onClick: () => {
            router.push("/settings");
          }
        }
      ]
    };
  },
  methods: {
    logout() {
      const confirmed = window.confirm(this.$i18n.t("confirm-logout"));
      if (confirmed) {
        localStorage.removeItem("loggedInUser");
        router.push("/login");
      }
    }
  },
  created() {
    startListeningToNotifications();
    if (
      localStorage.getItem("loggedInUser") === null ||
      localStorage.getItem("loggedInUser") === undefined
    ) {
      router.replace("/login");
    }
  }
};
</script>

<style scoped>
.main-screen-container {
  display: flex;
  flex-direction: row;
}
.main-screen-tab-bar {
  background-color: var(--primary-lower);
  overflow-x: hidden;
  overflow-y: auto;
  width: fit-content;
  height: 100dvh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logout {
  width: 100%;
}

.main-screen-logo-container {
  display: flex;
  align-items: center;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  gap: 1em;
  overflow: hidden;
  width: fit-content;
}

.main-screen-logo {
  height: 1.5em;
}

.main-screen-title {
  font-family: "BukhariScript", sans-serif;
  font-size: 1em;
  color: var(--primary);
}

.main-screen-list {
  margin-top: 1em;
}

.main-screen-content-container {
  flex: 1;
  height: 100dvh;
  display: flex;
  flex-direction: column;
}

.main-screen-slot-container {
  overflow: auto;
  height: 100%;
}

.loader {
  align-self: center;
  justify-self: center;
}
</style>
