<template>
  <div class="home-container">
    <!-- Hero Section -->
    <div class="landing-screen-logo-container">
      <img
        alt="Kowander logo"
        src="@/assets/logo-with-no-text.png"
        class="landing-screen-logo"
      />
      <p class="landing-screen-title">Kowander</p>
    </div>
    <section class="hero-section">
      <h1>{{ $i18n.t("welcome_message") }}</h1>
      <p>{{ $i18n.t("hero_description") }}</p>
      <button class="cta-button" @click="goToLogin">
        {{ $i18n.t("get_started") }}
      </button>
    </section>

    <!-- Features Section -->
    <section class="features-section">
      <h2>{{ $i18n.t("why_choose_kowander") }}</h2>
      <div class="features-list">
        <div class="feature-item">
          <h3>{{ $i18n.t("collaborative_trip_planning") }}</h3>
          <p>{{ $i18n.t("collaborative_trip_planning_description") }}</p>
        </div>
        <div class="feature-item">
          <h3>{{ $i18n.t("real_time_chat") }}</h3>
          <p>{{ $i18n.t("real_time_chat_description") }}</p>
        </div>
        <div class="feature-item">
          <h3>{{ $i18n.t("expense_tracking") }}</h3>
          <p>{{ $i18n.t("expense_tracking_description") }}</p>
        </div>
      </div>
    </section>

    <!-- Screenshots and Animations Section -->
    <section class="screenshots-section">
      <h2>{{ $i18n.t("explore_kowander_in_action") }}</h2>
      <div class="screenshots-container">
        <img
          src="@/assets/screenshot1.png"
          :alt="$i18n.t('screenshot_alt_1')"
          class="screenshot"
        />
        <img
          src="@/assets/screenshot2.png"
          :alt="$i18n.t('screenshot_alt_2')"
          class="screenshot"
        />
        <img
          src="@/assets/screenshot3.png"
          :alt="$i18n.t('screenshot_alt_3')"
          class="screenshot"
        />
      </div>
      <!-- Simple CSS Animation -->
      <div class="animation-container">
        <div class="animated-box"></div>
      </div>
    </section>

    <!-- Footer -->
    <footer class="footer">
      <p>{{ $i18n.t("footer_text") }}</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "LandingScreen",
  methods: {
    goToLogin() {
      this.$router.push("/login");
    }
  }
};
</script>

<style scoped>
/* General Layout */
.home-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  padding: 2em;
}

/* Header */
.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.landing-screen-logo-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.landing-screen-logo {
  height: 8em;
}

.landing-screen-title {
  font-family: "BukhariScript", sans-serif;
  font-size: 3em;
  color: var(--primary);
  margin: 0;
}

/* Hero Section */
.hero-section {
  text-align: center;
  padding: 4em 2em;
  padding-top: 1em;
}

.hero-section h1 {
  font-size: 3em;
  color: var(--primary);
  margin-bottom: 0.5em;
}

.hero-section p {
  font-size: 1.5em;
  color: var(--secondary);
  margin-bottom: 1.5em;
  max-width: 40em;
}

.cta-button {
  background-color: var(--primary);
  color: white;
  font-size: 1.2em;
  padding: 0.8em 2em;
  border: none;
  border-radius: 0.5em;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.cta-button:hover {
  background-color: var(--primary-lower);
  transform: scale(1.05);
}

/* Features Section */
.features-section {
  width: 100%;
  padding: 4em 2em;
  text-align: center;
}

.features-section h2 {
  font-size: 2.5em;
  color: var(--secondary);
  margin-bottom: 2em;
}

.features-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
}

.feature-item {
  background-color: white;
  padding: 2em;
  border-radius: 0.5em;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 25em;
  transition: transform 0.3s;
}

.feature-item:hover {
  transform: translateY(-10px);
}

.feature-item h3 {
  font-size: 1.8em;
  color: var(--primary);
  margin-bottom: 0.5em;
}

.feature-item p {
  font-size: 1.2em;
  color: var(--secondary);
}

.screenshots-section {
  width: 100%;
  padding: 4em 2em;
  text-align: center;
}

.screenshots-section h2 {
  font-size: 2.5em;
  color: var(--secondary);
  margin-bottom: 2em;
}

.screenshots-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5em;
}

.screenshot {
  width: 20em;
  border-radius: 0.5em;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.screenshot:hover {
  transform: scale(1.05);
}

/* Simple CSS Animation */
.animation-container {
  margin-top: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animated-box {
  width: 5em;
  height: 5em;
  background-color: var(--primary);
  border-radius: 50%;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-2em);
  }
  60% {
    transform: translateY(-1em);
  }
}

/* Footer */
.footer {
  width: 100%;
  max-width: 60em;
  text-align: center;
  padding: 2em;
  margin-top: auto;
}

.footer p {
  font-size: 1em;
  color: var(--secondary);
}

/* Responsive Design */
@media (max-width: 768px) {
  .features-list {
    flex-direction: column;
    align-items: center;
    margin-right: 4em;
  }

  .feature-item {
    width: 80%;
  }

  .screenshots-container {
    flex-direction: column;
    align-items: center;
  }

  .screenshot {
    width: 80%;
  }

  .hero-section h1 {
    font-size: 2.5em;
  }

  .hero-section p {
    font-size: 1.2em;
    max-width: 30em;
  }

  .cta-button {
    font-size: 1em;
    padding: 0.6em 1.5em;
  }

  .features-section h2,
  .screenshots-section h2 {
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  .feature-item {
    width: 100%;
  }

  .screenshot {
    width: 100%;
  }

  .hero-section h1 {
    font-size: 2em;
  }

  .features-section h2,
  .screenshots-section h2 {
    font-size: 1.8em;
  }
}
</style>
