<template>
  <div class="modal-background" v-if="isVisible" @click="closeModal()">
    <form
      @submit.prevent="requestNotification"
      ref="form"
      class="modal-container"
      @click.stop
    >
      <NavigationBar
        :title="$i18n.t('notifications')"
        :rightButtons="[{ title: $i18n.t('cancel'), action: closeModal }]"
      />
      <div class="modal-content">
        <div class="form-group">
          <label>{{ $i18n.t("notifications-request-body") }}</label>
        </div>
        <div class="form-group">
          <div class="buttons-container">
            <button @click="accept" class="button yes-button">
              <p>{{ $i18n.t("yes") }}</p>
            </button>
            <button @click="closeModal" class="button no-button">
              <p>{{ $i18n.t("no") }}</p>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import NavigationBar from "@/views/components/NavigationBar.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { requestNotificationPermission } from "@/notifications";

export default {
  name: "NotificationsRequestModal",
  components: { NavigationBar },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      trip: { ...this.initialTrip },
      range: [this.initialTrip?.startDate, this.initialTrip?.endDate]
    };
  },
  created() {},
  methods: {
    closeModal() {
      const confirmed = window.confirm(
        this.$i18n.t("confirm-cancel-notifications")
      );
      if (confirmed) {
        localStorage.setItem("requestedNotifications", true);
        this.$emit("close");
      }
    },
    async requestNotification() {
      await requestNotificationPermission();
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  overflow: auto;
  z-index: 1000;
}

.modal-container {
  background-color: var(--background-secondary);
  border-radius: 1em;
  width: 70dvw;
  max-width: 60em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin: auto;
  display: flex;
  flex-direction: column;
  max-height: 90dvh;
}

.modal-content {
  overflow: auto;
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
}

.form-group {
  margin-bottom: 1em;
}

.form-group label {
  display: block;
  margin-bottom: 0.2em;
  font-size: 1em;
}
.buttons-container {
  justify-content: space-around;
  display: flex;
  flex-direction: row;
}
.button {
  color: white;
  border: none;
  border-radius: 0.5em;
  padding: 0.5em 2.3em;
  cursor: pointer;
}
.yes-button {
  background-color: var(--primary);
}
.no-button {
  background-color: var(--background-secondary2);
}
</style>
