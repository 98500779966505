import axios from "axios";

class PreferenceRepository {
  static async fetchPreferences() {
    const response = await axios.get("preference");
    return response?.data?.data;
  }
  static async updatePreferences(preferences) {
    const response = await axios.put("preference", preferences);
    return response?.data?.data;
  }
}

export default PreferenceRepository;
