<template>
  <div class="modal-background" v-if="isVisible" @click="closeModal()">
    <form
      @submit.prevent="saveChanges"
      ref="form"
      class="modal-container"
      @click.stop
    >
      <NavigationBar
        :title="$i18n.t('trip')"
        :leftButtons="[{ title: $i18n.t('cancel'), action: closeModal }]"
        :rightButtons="[
          {
            title: $i18n.t('save'),
            color: 'var(--primary)',
            action: () => {
              const form = this.$refs.form;
              if (form.checkValidity()) {
                form.requestSubmit();
              } else {
                form.reportValidity();
              }
            }
          }
        ]"
      />
      <div class="modal-content">
        <div class="form-group">
          <label>{{ $i18n.t("title") }}</label>
          <input
            type="text"
            v-model="trip.name"
            required
            @input="fetchLocationSuggestions"
          />
        </div>
        <div class="form-group">
          <div class="date-picker-container">
            <Datepicker
              required
              v-model="range"
              range
              lang="en"
              :enableTimePicker="false"
              :inline="{ input: false }"
              :min-date="new Date()"
              :month-change-on-scroll="false"
              :auto-apply="true"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import NavigationBar from "@/views/components/NavigationBar.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "TripModal",
  components: { NavigationBar, Datepicker: VueDatePicker },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    initialTrip: {
      type: Object,
      default: undefined,
      required: false
    }
  },
  data() {
    return {
      trip: { ...this.initialTrip },
      range: [this.initialTrip?.startDate, this.initialTrip?.endDate]
    };
  },
  created() {},
  methods: {
    closeModal() {
      const confirmed = window.confirm(
        this.$i18n.t("confirm-discard-trip-changes")
      );
      if (confirmed) {
        this.$emit("close");
      }
    },
    saveChanges() {
      if (this.trip.name && this.range.length == 2) {
        this.trip.startDate = this.range[0];
        this.trip.endDate = this.range[1];
        this.$emit("save", this.trip);
      } else {
        alert(this.$i18n.t("please-fill-out-the-fields"));
      }
    }
  }
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  overflow: auto;
  z-index: 1000;
}

.modal-container {
  background-color: var(--background-secondary);
  border-radius: 1em;
  width: 90dvw;
  max-width: 90em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin: auto;
  display: flex;
  flex-direction: column;
  max-height: 90dvh;
}

.modal-content {
  overflow: auto;
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
}

.form-group {
  margin-bottom: 1em;
}

.form-group label {
  display: block;
  margin-bottom: 0.2em;
}
.form-group input {
  width: 100%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 0.5em;
  border: none;
  font-size: 1em;
}
.date-picker-container {
  margin: auto;
  width: fit-content;
}
</style>
