<template>
  <MainScreen
    :isLoading="isLoading"
    :showBackButton="true"
    :title="trip?.name"
    :rightButtons="[
      {
        icon: 'add',
        action: () => {
          showAddExpensesModal = true;
        }
      }
    ]"
  >
    <div class="expenses-container">
      <div
        v-if="
          trip &&
          expensesOverview &&
          travelers.length > 0 &&
          expensesOverview.actions?.length
        "
      >
        <p class="section-title">{{ $i18n.t("overview") }}</p>
        <div class="items-container">
          <!-- Iterate over all other travelers -->
          <div v-for="(user, index) in travelers" :key="user._id" class="item">
            <div
              class="item-title-container"
              :class="index < travelers.length - 1 ? 'separator' : ''"
            >
              <p class="item-title">
                <span v-if="getExpenseActionFrom(user)">
                  {{
                    $i18n.t("owes-you", {
                      user: user.displayName,
                      amount: getExpenseActionFrom(user)?.amount
                    })
                  }}
                </span>
                <span v-else-if="getExpenseActionTo(user)">
                  {{
                    $i18n.t("you-owe", {
                      user: user.displayName,
                      amount: getExpenseActionTo(user)?.amount
                    })
                  }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Expenses Section -->
      <div v-if="expensesOverview?.expenses.length">
        <p class="section-title">{{ $i18n.t("expenses") }}</p>
        <div class="items-container">
          <div
            v-for="expense in expensesOverview.expenses"
            :key="expense._id"
            class="item clickable"
            @click="selectExpense(expense)"
          >
            <div class="item-title-container">
              <p class="item-title">
                {{
                  getPaidUser(expense)._id === currentUser._id
                    ? $i18n.t("you-paid-on-behalf", {
                        user: getShares(expense),
                        amount: expense.amount,
                        title: expense.title
                      })
                    : $i18n.t("user-paid-on-behalf", {
                        user: getPaidUser(expense).displayName,
                        secondUser: getShares(expense),
                        amount: expense.amount,
                        title: expense.title
                      })
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <AddExpenseModal
        v-if="showAddExpensesModal"
        :isVisible="showAddExpensesModal"
        :initialTrip="trip"
        :initialExpense="selectedExpense"
        @close="closeAddExpensesModal"
        @reload="fetchExpenses"
      />
    </div>
  </MainScreen>
</template>

<script>
import TripsRepository from "@/repositories/TripsRepository";
import ExpensesRepository from "@/repositories/ExpensesRepository";
import AddExpenseModal from "@/views/modal/AddExpenseModal.vue";
import MainScreen from "./components/MainScreen.vue";

export default {
  name: "ExpensesScreen",
  components: { MainScreen, AddExpenseModal },
  data() {
    return {
      trip: undefined,
      selectedExpense: undefined,
      expensesOverview: undefined,
      showAddExpensesModal: false,
      isLoading: true,
      currentUser: JSON.parse(localStorage.getItem("loggedInUser"))
    };
  },
  computed: {
    travelers() {
      return [...(this.trip?.users ?? []), ...(this.trip?.owners ?? [])].filter(
        (user) => {
          return user._id !== this.currentUser._id;
        }
      );
    }
  },
  methods: {
    closeAddExpensesModal() {
      this.selectedExpense = undefined;
      this.showAddExpensesModal = false;
    },
    async selectExpense(expense) {
      this.selectedExpense = expense;
      this.showAddExpensesModal = true;
    },
    getExpenseActionFrom(user) {
      return this.expensesOverview?.actions.find(
        (action) => action.from._id === user._id
      );
    },
    getExpenseActionTo(user) {
      return this.expensesOverview?.actions.find(
        (action) => action.to._id === user._id
      );
    },
    getPaidUser(expense) {
      return expense.shares.find((share) => share.didPay).user;
    },
    getShares(expense) {
      const users = expense.shares.map((share) =>
        share.user?._id === this.currentUser?._id
          ? this.$i18n.t("you")
          : share.user.displayName
      );
      if (users.length > 1) {
        const lastUser = users.pop();
        return this.$i18n.t("and-users", {
          firstUser: users.join(", "),
          lastUser: lastUser
        });
      } else {
        return users.join(", ");
      }
    },
    async fetchExpenses() {
      this.isLoading = true;
      try {
        this.trip = await TripsRepository.fetchTrip(this.$route.params.id);
        this.expensesOverview = await ExpensesRepository.fetch(
          this.$route.params.id
        );
      } catch (error) {
        console.error("Failed to load expenses");
      } finally {
        this.isLoading = false;
      }
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.fetchExpenses();
    }
  }
};
</script>

<style scoped>
.expenses-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.section-title {
  padding-left: 2.5em;
  font-size: 1em;
  margin-bottom: 0.1em;
}
.items-container {
  background-color: var(--background-secondary);
  border-radius: 1em;
  margin-top: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  overflow: hidden;
}
.item {
  padding-left: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.clickable:hover {
  cursor: pointer;
  background-color: var(--primary-lower);
}
.separator {
  border-bottom: solid 0.1em rgba(0, 0, 0, 0.1);
}
.item-title {
  flex: 1;
  font-size: 1.2em;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  margin: 0;
}
.item-title-container {
  flex: 1;
  display: flex;
  align-items: center;
}
.item-value {
  font-size: 1em;
  margin: 0;
  margin-right: 1em;
}
</style>
