<template>
  <MainScreen
    :isLoading="isLoading"
    :showBackButton="false"
    :rightButtons="[
      {
        icon: 'add',
        action: () => {
          showTripModal = true;
        }
      }
    ]"
  >
    <div class="home-container">
      <TripItem
        v-for="item in trips"
        :isSelected="item._id === $route.params?.id"
        :key="item._id"
        :title="item.name"
        @titleClick="selectTrip(item)"
        @detailsClick="selectDetails(item)"
        @mapClick="selectMap(item)"
        @chatClick="selectChat(item)"
        @expensesClick="selectExpenses(item)"
      />
      <TripModal
        v-if="showTripModal"
        :isVisible="showTripModal"
        :initialTrip="trip"
        @close="closeTripModal"
        @save="handleTripSave"
      />
      <NotificationsRequestModal
        v-if="showNotificationsModal"
        :isVisible="showNotificationsModal"
        @close="closeNotificationsModal"
      />
    </div>
  </MainScreen>
</template>

<script>
import router from "@/router";
import TripItem from "@/views/components/TripItem.vue";
import TripModal from "@/views/modal/TripModal.vue";
import NotificationsRequestModal from "@/views/modal/NotificationsRequestModal.vue";
import TripsRepository from "@/repositories/TripsRepository";
import MainScreen from "@/views/components/MainScreen.vue";

export default {
  name: "HomeScreen",
  components: { TripItem, TripModal, MainScreen, NotificationsRequestModal },
  data() {
    return {
      showTripModal: false,
      showNotificationsModal: false,
      trips: undefined,
      trip: { locations: [], users: [] },
      isLoading: true
    };
  },
  methods: {
    async selectTrip(item) {
      if (this.$route.params?.id == item._id) {
        router.replace("/home");
      } else {
        router.replace("/home/" + item._id);
      }
    },
    async selectDetails(item) {
      router.push("/details/" + item._id);
    },
    async selectMap(item) {
      router.push("/map/" + item._id);
    },
    async selectChat(item) {
      router.push("/chat/" + item._id);
    },
    async selectExpenses(item) {
        router.push("/expenses/" + item._id);
    },
    closeTripModal() {
      this.showTripModal = false;
    },
    closeNotificationsModal() {
      this.showNotificationsModal = false;
    },
    async handleTripSave(trip) {
      const addedTrip = await TripsRepository.addTrip(trip);
      this.trips = addedTrip;
      this.closeTripModal();
    },
    async fetchTrips() {
      this.isLoading = true;
      try {
        this.trips = await TripsRepository.fetchTrips();
      } catch (error) {
        alert(error);
      }
      this.isLoading = false;
    }
  },
  async created() {
    await this.fetchTrips();
    if (
      Notification.permission === "default" &&
      !localStorage.getItem("requestedNotifications")
    ) {
      this.showNotificationsModal = true;
    }
  }
};
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
