import axios from "axios";

class NotificationRepository {
  static async register(token) {
    await axios.post("notification/register", { token: token });
  }
  static async notify(user) {
    const response = await axios.post("notification/notify", { _id: user._id });
    return response?.data?.data;
  }
}

export default NotificationRepository;
