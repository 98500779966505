<template>
  <div class="modal-background" v-if="isVisible" @click="closeModal()">
    <form
      @submit.prevent="saveChanges"
      ref="form"
      class="modal-container"
      @click.stop
    >
      <NavigationBar
        :title="$i18n.t('add_travelers')"
        :leftButtons="[{ title: $i18n.t('cancel'), action: closeModal }]"
        :rightButtons="[
          {
            title: $i18n.t('save'),
            color: 'var(--primary)',
            action: () => {
              const form = this.$refs.form;
              if (form.checkValidity()) {
                form.requestSubmit();
              } else {
                form.reportValidity();
              }
            }
          }
        ]"
      />
      <div class="modal-content">
        <p class="section-title">{{ $i18n.t("users") }}</p>
        <div class="connections-list-container">
          <ListItem
            v-for="(traveler, index) in travelers"
            :key="traveler._id"
            :title="traveler.displayName"
            :withSeparator="index < travelers.length - 1"
            :withArrow="false"
          />
        </div>
      </div>

      <div class="modal-content">
        <p class="section-title">{{ $i18n.t("add") }}</p>
        <input
          type="text"
          v-model="searchQuery"
          :placeholder="$i18n.t('search')"
          class="search-input"
        />
        <div class="connections-list-container">
          <ListItem
            v-for="(traveler, index) in filteredConnections"
            :key="traveler._id"
            :title="traveler.displayName"
            :withSeparator="index < filteredConnections.length - 1"
            :onCLick="() => add(traveler)"
            :withArrow="false"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import NavigationBar from "@/views/components/NavigationBar.vue";
import ListItem from "@/views/components/ListItem.vue";
import UserRepository from "@/repositories/UserRepository";

export default {
  name: "AddTravelersModal",
  components: { NavigationBar, ListItem },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    initialTrip: {
      type: Object,
      default: undefined,
      required: false
    }
  },
  data() {
    return {
      trip: { ...this.initialTrip },
      users: [...this.initialTrip.users],
      connections: [],
      searchQuery: ""
    };
  },
  computed: {
    travelers() {
      return [...this.users, ...this.trip.owners];
    },
    filteredConnections() {
      const lowerCaseQuery = this.searchQuery.toLowerCase();
      return this.connections
        .filter((connection) => {
          return !this.travelers.some((traveler) => {
            return traveler._id === connection._id;
          });
        })
        .filter((connection) => {
          return connection.displayName.toLowerCase().includes(lowerCaseQuery);
        });
    }
  },
  async created() {
    this.isLoading = true;
    this.connections = await UserRepository.fetchConnections();
    this.isLoading = false;
  },
  methods: {
    closeModal() {
      const confirmed = window.confirm(
        this.$i18n.t("confirm-discard-trip-changes")
      );
      if (confirmed) {
        this.$emit("close");
      }
    },
    add(traveler) {
      this.users.push(traveler);
      this.filteredConnections;
    },
    saveChanges() {
      if (this.trip.name) {
        this.trip.users = this.users;
        this.$emit("save", this.trip);
      } else {
        alert(this.$i18n.t("please-fill-out-the-fields"));
      }
    }
  }
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  overflow: auto;
  z-index: 1000;
}

.modal-container {
  background-color: var(--background-secondary);
  border-radius: 1em;
  width: 90dvw;
  max-width: 90em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin: auto;
  display: flex;
  flex-direction: column;
  max-height: 90dvh;
}

.modal-content {
  overflow: auto;
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  flex-direction: column;
}

.section-title {
  padding-left: 2.5em;
  font-size: 1em;
  margin-bottom: 0;
}

.connections-list-container {
  border-radius: 1em;
  background-color: var(--background-secondary2);
  margin-top: 1em;
  margin-bottom: 1em;
  overflow: hidden;
}

.search-input {
  flex: 1;
  padding: 0.75rem;
  margin: 1em;
  margin-bottom: 0.1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}
</style>
