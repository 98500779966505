import { messaging } from "@/firebaseConfig";
import NotificationRepository from "@/repositories/NotificationRepository";

export async function requestNotificationPermission() {
    try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await messaging.getToken({
        vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY
      });
      NotificationRepository.register(token);
      return token;
    }
  } catch (error) {
    console.error("");
  }
}

export async function startListeningToNotifications() {
  messaging.onMessage((payload) => {
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon
    };

    new Notification(notificationTitle, notificationOptions);
  });
}
