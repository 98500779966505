<template>
  <MainScreen
    :isLoading="isLoading"
    :showBackButton="true"
    :title="user?.displayName"
  >
    <div class="user-profile-container">
      <div class="header" v-if="!isLoading">
        <qrcode-vue
          v-if="user"
          :value="user.qrCode"
          level="H"
          render-as="svg"
          class="qr-code"
        />
        <p class="email">{{ user.email }}</p>
      </div>
      <div class="buttons-container" v-if="!isLoading">
        <div class="button" v-if="!user.isFriend" @click="add(user)">
          <p class="button-title">{{ $i18n.t("add") }}</p>
        </div>
        <div class="button" v-if="user.isFriend" @click="notify(user)">
          <p class="button-title">{{ $i18n.t("ping") }}</p>
        </div>
        <div class="button" v-if="user.isFriend" @click="remove(user)">
          <p class="button-title">{{ $i18n.t("remove") }}</p>
        </div>
      </div>
    </div>
  </MainScreen>
</template>

<script>
import NotificationRepository from "@/repositories/NotificationRepository";
import UserRepository from "@/repositories/UserRepository";
import QrcodeVue from "qrcode.vue";
import MainScreen from "@/views/components/MainScreen.vue";

export default {
  name: "UserProfile",
  components: { QrcodeVue, MainScreen },
  props: {
    userID: String
  },
  data() {
    return {
      user: undefined,
      isLoading: true
    };
  },
  methods: {
    notify(user) {
      NotificationRepository.notify(user);
    },
    async add(user) {
      const confirmed = window.confirm(
        this.$i18n.t("confirm-add-user", { name: user.displayName })
      );
      if (confirmed) {
        try {
          this.isLoading = true;
          await UserRepository.addConnection(user);
          await this.fetchUser()
          this.isLoading = false;
        } catch (error) {
          alert(error);
        }
      }
    },
    async remove(user) {
      const confirmed = window.confirm(
        this.$i18n.t("confirm-remove-user", { name: user.displayName })
      );
      if (confirmed) {
        try {
          this.isLoading = true;
          await UserRepository.removeConnection(user);
          await this.fetchUser()
          this.isLoading = false;
        } catch (error) {
          alert(error);
        }
      }
    },
    async fetchUser() {
      this.isLoading = true;
      this.user = await UserRepository.fetchUser(this.$route.params.id);
      this.isLoading = false;
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.fetchUser();
    }
  }
};
</script>

<style scoped>
.user-profile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.qr-code {
  margin-top: 1em;
  width: 10em;
  height: 10em;
}
.email {
  text-align: center;
  font-size: min(3vw, 1em);
  max-width: 100%;
}
.buttons-container {
  display: flex;
  justify-content: center;
  gap: 1em;
  flex-direction: row;
}
.button {
  background-color: var(--background-secondary);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 1em;
  cursor: pointer;
}
.button:hover {
  background-color: var(--primary-lower);
}
.button-title {
  margin: 0;
}
</style>
