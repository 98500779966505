<template>
  <MainScreen :isLoading="isLoading" :showBackButton="true" :title="trip?.name">
    <div class="chat-container">
      <div class="messages-list" ref="messagesList">
        <div
          v-for="(message, index) in messages"
          :key="index"
          :class="[
            'message-box',
            message.user._id === currentUser._id ? 'sent' : 'received'
          ]"
        >
          <p class="message-sender">{{ message.user?.displayName }}</p>
          <p class="message-text" v-if="message.type === 'text'">
            {{ message.text }}
          </p>
          <p class="message-time">{{ formatTimestamp(message.createdAt) }}</p>
        </div>
      </div>
      <div class="message-input-container">
        <input
          type="text"
          v-model="newMessage"
          @keyup.enter="sendMessage"
          placeholder="Type your message..."
          class="message-input"
        />
        <button @click="sendMessage" class="send-button">Send</button>
      </div>
    </div>
  </MainScreen>
</template>

<script>
import TripsRepository from "@/repositories/TripsRepository";
import MessagesRepository from "@/repositories/MessagesRepository";
import MainScreen from "@/views/components/MainScreen.vue";
import { database, auth } from "@/firebaseConfig";
import dayjs from "dayjs";

export default {
  name: "ChatScreen",
  components: { MainScreen },
  data() {
    return {
      isLoading: true,
      tripID: this.$route.params.id,
      trip: undefined,
      messages: [],
      newMessage: "",
      currentUser: JSON.parse(localStorage.getItem("loggedInUser"))
    };
  },
  methods: {
    async fetchTrip() {
      this.isLoading = true;
      try {
        this.trip = await TripsRepository.fetchTrip(this.tripID);
      } catch (error) {
        alert(error);
      }
      this.isLoading = false;
    },
    async fetchMessages() {
      try {
        this.messages = await MessagesRepository.fetch(this.tripID);
      } catch (error) {
        alert(error);
      }
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    formatTimestamp(timestamp) {
      return dayjs(timestamp).format("HH:mm");
    },
    async sendMessage() {
      if (this.newMessage.trim() !== "") {
        const newMessage = this.newMessage;
        this.newMessage = "";
        try {
          await MessagesRepository.send(this.tripID, newMessage);
        } catch (error) {
          alert(error);
        }
      }
    },
    scrollToBottom() {
      const container = this.$refs.messagesList;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
    listenForMessages() {
      if (!this.tripID || !this.currentUser) return;
      const messagesRef = database.ref(
        `trip/${this.tripID}/user/${this.currentUser._id}/messages/`
      );
      auth
        .signInAnonymously()
        .then(() => {
          messagesRef.on("value", (snapshot) => {
            const messagesData = snapshot.val();
            if (messagesData) {
              this.fetchMessages();
            }
          });
        })
        .catch((error) => {
          console.error("Error authenticating with Firebase:", error);
        });
    },
    stopListening() {
      const messagesRef = database.ref(
        `trip/${this.tripID}/user/${this.currentUser._id}/messages/`
      );
      messagesRef.off();
    }
  },
  async created() {
    this.fetchTrip();
    this.isLoading = true;
    await this.fetchMessages();
    this.isLoading = false;
    this.listenForMessages();
  },
  beforeUnmount() {
    this.stopListening();
  }
};
</script>

<style scoped>
.chat-container {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding: 1em;
  height: calc(100dvh - 5em);
}

.messages-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 1em;
  height: 100%;
}

.message-box {
  max-width: 60%;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  margin-bottom: 0.5em;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  position: relative;
}

.message-sender {
  font-weight: bold;
  margin-bottom: 0.25em;
}

.message-text {
  margin: 0;
}

.message-time-sent {
  font-size: 0.75em;
  color: white;
  align-self: flex-end;
  margin-top: 0.5em;
}

.message-time-received {
  font-size: 0.75em;
  align-self: flex-end;
  margin-top: 0.5em;
}

.sent {
  background-color: var(--primary);
  align-self: flex-end;
  color: white;
}

.received {
  background-color: var(--background-secondary);
  align-self: flex-start;
}

.message-input-container {
  display: flex;
  align-items: center;
  border-top: 1px solid #ddd;
  padding-top: 0.5em;
}

.message-input {
  flex: 1;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 0.5em;
}

.send-button {
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 0.5em;
  padding: 0.5em 1em;
  cursor: pointer;
}

.send-button:hover {
  background-color: var(--primary);
}
</style>
