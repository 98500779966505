<template>
  <MainScreen
    :isLoading="isLoading"
    :showBackButton="false"
    :title="user?.displayName"
    :rightButtons="[
      {
        icon: 'share',
        action: share
      }
    ]"
  >
    <div class="my-profile-container">
      <div class="header" v-if="!isLoading">
        <qrcode-vue
          v-if="user"
          :value="user.qrCode"
          level="H"
          render-as="svg"
          class="qr-code"
        />
        <p class="email">{{ user.email }}</p>
      </div>
      <div class="section-title-container">
        <p class="section-title">{{ $i18n.t("friends") }}</p>
        <v-icon class="material-icons add-icon" @click="addFriend">add</v-icon>
      </div>
      <div class="connections-list-container">
        <ListItem
          v-for="(connection, index) in connections"
          :key="connection._id"
          :title="connection.displayName"
          :withSeparator="index < connections.length - 1"
          :onCLick="() => goTo(connection)"
        />
      </div>
    </div>
    <QRCodeScannerModal
      v-if="showQRCodeScanner"
      :isVisible="showQRCodeScanner"
      @close="closeQRCodeScanner"
    />
  </MainScreen>
</template>

<script>
import router from "@/router";
import UserRepository from "@/repositories/UserRepository";
import QrcodeVue from "qrcode.vue";
import QRCodeScannerModal from "@/views/modal/QRCodeScannerModal.vue";
import MainScreen from "@/views/components/MainScreen.vue";
import ListItem from "@/views/components/ListItem.vue";

export default {
  name: "MyProfile",
  components: { QrcodeVue, MainScreen, ListItem, QRCodeScannerModal },
  props: {},
  data() {
    return {
      user: JSON.parse(localStorage.getItem("loggedInUser")),
      connections: undefined,
      isLoading: true,
      showQRCodeScanner: false
    };
  },
  methods: {
    goTo(connection) {
      router.push("profile/" + connection._id);
    },
    async share() {
      if (navigator.share && this.user.qrCode) {
        try {
          await navigator.share({
            title: "Add me on kowander!",
            text: "Hey 👋!\nAdd me on Kowander. Let's share our adventures together! ✈️",
            url: "https://kowander.com/add/" + this.user.qrCode
          });
          console.log("Shared successfully");
        } catch (error) {
          console.error("Error sharing", error);
        }
      } else {
        alert("Your browser does not support the Web Share API.");
      }
    },
    addFriend() {
      this.showQRCodeScanner = true;
    },
    closeQRCodeScanner() {
        
      this.showQRCodeScanner = false;
    }
  },
  async created() {
    if (this.user) {
      this.isLoading = true;
      this.user = await UserRepository.fetchUser(this.user._id);
      this.connections = await UserRepository.fetchConnections();
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
.my-profile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.qr-code {
  margin-top: 1em;
  width: 10em;
  height: 10em;
}
.email {
  text-align: center;
}
.section-title-container {
  display: flex;
  flex-direction: row;
  padding-left: 2em;
  padding-right: 2em;
  margin-bottom: 0;
  justify-content: space-between;
}
.section-title {
  font-size: 1em;
  margin: 0;
}
.add-icon {
  cursor: pointer;
}
.connections-list-container {
  border-radius: 1em;
  background-color: var(--background-secondary);
  margin: 1em;
  overflow: hidden;
}
</style>
