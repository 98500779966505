<!-- src/components/ContactUs.vue -->

<template>
  <div class="contact-form-container">
    <div class="contact-form-box">
      <img alt="Kowander logo" src="@/assets/logo.png" class="logo" />
      <h2>Contact Us</h2>
      <form @submit.prevent="handleSubmit">
        <div class="input-group">
          <label for="name">Name</label>
          <input
            type="text"
            id="name"
            v-model="name"
            placeholder="Your Name"
            required
          />
        </div>

        <div class="input-group">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            v-model="email"
            placeholder="Your Email"
            required
          />
        </div>

        <div class="input-group">
          <label for="message">Message</label>
          <textarea
            id="message"
            v-model="message"
            placeholder="Your Message"
            required
          ></textarea>
        </div>

        <button type="submit">Send Message</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
      valid: false
    };
  },
  methods: {
    async handleSubmit() {
      await axios.post("/public/contactus", {
        name: this.name,
        email: this.email,
        message: this.message
      });
      this.name = "";
      this.email = "";
      this.message = "";
    }
  }
};
</script>

<style scoped>
.contact-form-container {
  height: 100dvh;
  padding-top: 1rem;
  padding-left: auto;
  padding-right: auto;
}
.contact-form-box {
  width: fit-content;
  /* margin-top: 1rem; */
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: var(--primary-lower);
}

.logo {
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

h2 {
  text-align: center;
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input,
textarea {
  width: 30rem;
  max-width: 70dvw;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

textarea {
  height: 150px;
  resize: vertical;
}

button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: var(--primary);
}
</style>
