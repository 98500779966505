<template>
  <div
    class="item-content"
    :class="{ selected: isSelected }"
    @click="$emit('click')"
  >
    <div class="tab-item">
      <p>{{ title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabItem",
  props: {
    title: {
      type: String,
      required: true
    },
    onClick: Function,
    isSelected: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.tab-item {
  text-decoration: none;
  color: inherit;
  text-align: center;
}

.item-content {
  cursor: pointer;
  padding: 0.5em;
  transition: background-color 0.3s ease;
  font-size: 1em;
  margin: 0;
}

.item-content:hover {
  background-color: var(--primary-lower);
}

.selected {
  background-color: var(--primary-lower);
}
</style>
