<template>
  <div class="item-content">
    <div class="trip-title-container-container" @click="$emit('titleClick')">
      <div class="trip-title-container">
        <p>{{ title }}</p>
        <v-icon class="material-icons arrow-icon">{{
          isSelected ? "keyboard_arrow_down" : "keyboard_arrow_right"
        }}</v-icon>
      </div>
    </div>
    <div v-if="isSelected" class="trip-items">
      <ListItem
        class="trip-item"
        :title="$i18n.t('details')"
        :withArrow="false"
        :withSeparator="false"
        icon="border_color"
        :onCLick="
          () => {
            $emit('detailsClick');
          }
        "
      />
      <ListItem
        class="trip-item"
        :title="$i18n.t('viewOnMap')"
        :withArrow="false"
        :withSeparator="false"
        icon="map"
        :onCLick="
          () => {
            $emit('mapClick');
          }
        "
      />

      <ListItem
        class="trip-item"
        :title="$i18n.t('chat')"
        :withArrow="false"
        :withSeparator="false"
        icon="chat"
        :onCLick="
          () => {
            $emit('chatClick');
          }
        "
      />
      <ListItem
        class="trip-item"
        :title="$i18n.t('expenses')"
        :withArrow="false"
        :withSeparator="false"
        icon="ballot"
        :onCLick="
          () => {
            $emit('expensesClick');
          }
        "
      />
    </div>
  </div>
</template>

<script>
import ListItem from "@/views/components/ListItem.vue";
export default {
  name: "TripItem",
  components: {
    ListItem
  },
  props: {
    title: {
      type: String,
      required: true
    },
    path: String,
    onClick: Function,
    isSelected: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.trip-title-container-container {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  padding: 0.1em;
  padding-left: 2em;
  padding-right: 1em;
  transition: background-color 0.3s ease;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.trip-title-container {
  padding-left: 1em;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  border-bottom: solid 0.1em rgba(0, 0, 0, 0.1);
}

.arrow-icon {
  font-size: 1.5em;
}

/* .trip-item { */
/* cursor: pointer; */
/* padding: 0.1em; */
/* padding-left: 2em; */
/* transition: background-color 0.3s ease; */
/* margin: 0; */
/* display: flex; */
/* align-items: center; */
/* gap: 0.5em; */
/* } */

.trip-item-icon {
  font-size: 1.5em;
  color: var(--primary);
}

/* .trip-title:hover {
  background-color: var(--primary-lower);
} */

/* .trip-item:hover {
  background-color: var(--primary-lower);
} */
</style>
