<template>
  <MainScreen
    :isLoading="isLoading"
    :title="this.trip?.name"
    :showBackButton="true"
  >
    <div class="map-container">
      <input
        type="text"
        :placeholder="$i18n.t('search')"
        v-model="newLocation.name"
        :required="true"
        @input="fetchLocationSuggestions"
        class="search-input"
      />
      <ul v-if="suggestions.length" class="suggestions-list">
        <li
          v-for="(suggestion, index) in suggestions"
          :key="index"
          @click="selectSuggestion(suggestion)"
        >
          {{ suggestion.description }}
        </li>
      </ul>
      <GoogleMap
        :api-key="googleMapAPIKey"
        style="width: 100%; height: 100%"
        :center="center"
        :zoom="defaultZoom"
        ref="googleMap"
        @idle="mapIsIdle"
        map-id="MapID"
        @click="updateMarkerLocation"
      >
        <AdvancedMarker
          v-for="location in trip?.locations"
          :key="location._id"
          :options="{
            position: { lat: location.latitude, lng: location.longitude },
            title: location.name
          }"
        >
          <InfoWindow>
            <div class="info-window-container">
              <h2 class="info-window-location-name">{{ location.name }}</h2>
              <p>
                {{ location.description }}
              </p>
            </div>
          </InfoWindow>
        </AdvancedMarker>

        <CustomMarker
          v-if="newLocation && newLocation.latitude && newLocation.longitude"
          :options="{
            position: { lat: newLocation.latitude, lng: newLocation.longitude },
            anchorPoint: 'BOTTOM_CENTER',
            title: 'New Location',
            gmpDraggable: true
          }"
        >
          <div class="new-location-marker">
            <div style="font-size: 1em">{{ newLocation.name }}</div>
            <img
              alt="Kowander logo"
              src="@/assets/logo-with-no-text.png"
              width="40"
              height="50"
            />
            <div class="new-location-buttons-container">
              <button
                class="new-location-cancel-button"
                @click.stop
                @click="cancelNewLocation"
              >
                {{ $i18n.t("cancel") }}
              </button>
              <button
                class="new-location-add-button"
                @click.stop
                @click="saveNewLocation"
              >
                {{ $i18n.t("save") }}
              </button>
            </div>
          </div>
        </CustomMarker>

        <CustomControl position="BOTTOM_LEFT">
          <button class="center-map-button" @click="fitMapToMarkers">
            {{ $i18n.t("center-map") }}
          </button>
        </CustomControl>
      </GoogleMap>
      <LocationModal
        v-if="showLocationModal"
        :isVisible="showLocationModal"
        :initialLocation="newLocation"
        :initialDate="
          trip.locations[trip.locations.length - 1]?.startDate ??
          trip?.startDate
        "
        @close="closeLocationModal"
        @save="handleLocationSave"
      />
    </div>
  </MainScreen>
</template>

<script>
import TripsRepository from "@/repositories/TripsRepository";
import LocationModal from "@/views/modal/LocationModal.vue";

import {
  GoogleMap,
  AdvancedMarker,
  CustomMarker,
  InfoWindow,
  CustomControl
} from "vue3-google-map";
import MainScreen from "./components/MainScreen.vue";

export default {
  name: "MapScreen",
  components: {
    GoogleMap,
    AdvancedMarker,
    CustomMarker,
    InfoWindow,
    MainScreen,
    CustomControl,
    LocationModal
  },
  data() {
    return {
      googleMapAPIKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      trip: undefined,
      isLoading: true,
      center: { lat: 2.323, lng: 4.234234 },
      tripID: this.$route.params?.id,
      defaultZoom: 15,
      alreadyLoaded: false,
      newLocation: {},
      suggestions: [],
      autocompleteService: undefined,
      showLocationModal: false
    };
  },
  methods: {
    async fetchTrip() {
      this.isLoading = true;
      try {
        this.trip = await TripsRepository.fetchTrip(this.tripID);
      } catch (error) {
        alert(error);
      }
      this.isLoading = false;
    },
    async mapIsIdle() {
      if (!this.alreadyLoaded) {
        await this.fitMapToMarkers();
        this.alreadyLoaded = true;
      }
    },
    async fitMapToMarkers() {
      try {
        const googleMapInstance = await this.$refs.googleMap;
        const mapInstance = googleMapInstance.map;
        if (!mapInstance || !this.trip?.locations?.length) {
          console.error("Map instance or locations are not ready");
          return;
        }
        const bounds = new window.google.maps.LatLngBounds();
        this.trip.locations.forEach((location) => {
          const position = new window.google.maps.LatLng(
            location.latitude,
            location.longitude
          );
          bounds.extend(position);
        });
        if (
          this.newLocation &&
          this.newLocation.longitude &&
          this.newLocation.latitude
        ) {
          const position = new window.google.maps.LatLng(
            this.newLocation.latitude,
            this.newLocation.longitude
          );
          bounds.extend(position);
        }
        mapInstance.fitBounds(bounds);
      } catch (_) {
        return;
      }
    },
    async centerLocation(location) {
      try {
        const googleMapInstance = await this.$refs.googleMap;
        const mapInstance = googleMapInstance.map;
        if (!mapInstance || !this.trip?.locations?.length) {
          return;
        }
        const position = new window.google.maps.LatLng(
          location.latitude,
          location.longitude
        );
        this.center = position;
      } catch (_) {
        return;
      }
    },
    fetchLocationSuggestions() {
      if (this.newLocation.name.length > 2 && this.autocompleteService) {
        this.autocompleteService.getPlacePredictions(
          { input: this.newLocation.name },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              this.suggestions = predictions;
            }
          }
        );
      }
    },
    selectSuggestion(suggestion) {
      this.newLocation.name = suggestion.description;
      this.suggestions = [];

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ placeId: suggestion.place_id }, (results, status) => {
        if (status === "OK" && results[0]) {
          const location = results[0].geometry.location;
          this.newLocation.latitude = location.lat();
          this.newLocation.longitude = location.lng();
          this.centerLocation(this.newLocation);
        }
      });
    },
    initGoogleAPI() {
      try {
        const googleMaps = window.google.maps;
        this.autocompleteService = new googleMaps.places.AutocompleteService();
      } catch (error) {
        console.error("Failed to initialize Google API: ", error);
      }
    },
    updateMarkerLocation(event) {
      if (this.newLocation.name && this.newLocation.name.length) {
        this.newLocation.latitude = event.latLng.lat();
        this.newLocation.longitude = event.latLng.lng();
      }
    },
    saveNewLocation() {
      this.showLocationModal = true;
    },
    cancelNewLocation() {
      this.newLocation = {};
    },
    async handleLocationSave(newLocation) {
      this.trip.locations.push(newLocation);
      await this.saveTrip();
      this.closeLocationModal();
    },
    closeLocationModal() {
      this.newLocation = {};
      this.showLocationModal = false;
    },
    async saveTrip() {
      this.isLoading = true;
      await TripsRepository.saveTrip(this.trip);
      this.isLoading = false;
    }
  },
  async created() {
    await this.fetchTrip();
    this.initGoogleAPI();
  }
};
</script>

<style scoped>
.map-container {
  width: 100%;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.info-window-container {
  color: var(--secondary);
}
.info-window-location-name {
  margin: 0;
}
.search-input {
  flex: 1;
  padding: 0.5em;
  border-radius: 0.5em;
  border: none;
  font-size: 1em;
  margin: 0.5em;
}
.suggestions-list {
  list-style: none;
  padding: 0;
  margin-top: 2.8em;
  background-color: var(--background-secondary);
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: var(--primary-lower);
}

.center-map-button {
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 0.5em;
  padding: 0.5em 1em;
  cursor: pointer;
}

.new-location-marker {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.new-location-buttons-container {
  display: flex;
  flex-direction: row;
}

.new-location-add-button {
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 0.5em;
  margin: 0.1em;
  padding: 0.5em 0.5em;
  cursor: pointer;
}

.new-location-cancel-button {
  background-color: var(--danger);
  color: white;
  border: none;
  border-radius: 0.5em;
  margin: 0.1em;
  padding: 0.5em 0.5em;
  cursor: pointer;
}
</style>
