<template>
  <div class="navigation-bar">
    <div class="back-container" v-if="showBackButton" @click="goBack">
      <v-icon class="material-icons arrow-icon">keyboard_arrow_left</v-icon>
    </div>
    <div class="left-icons-container" v-if="!showBackButton">
      <v-icon
        v-for="(leftButton, index) in leftButtons"
        :style="{ color: leftButton.color }"
        v-show="leftButton.icon"
        :key="index"
        class="material-icons left-icon"
        @click="leftButton.action"
        >{{ leftButton.icon }}</v-icon
      >
      <p
        v-for="(leftButton, index) in leftButtons"
        :style="{ color: leftButton.color }"
        :key="index"
        v-show="leftButton.title"
        @click="leftButton.action"
        class="left-button"
      >
        {{ leftButton.title }}
      </p>
    </div>
    <p class="navigation-title">{{ title }}</p>
    <div class="right-icons-container">
      <v-icon
        v-for="(rightButton, index) in rightButtons"
        :style="{ color: rightButton.color }"
        :key="index"
        class="material-icons right-icon"
        @click="rightButton.action"
        >{{ rightButton.icon }}</v-icon
      >
      <p
        v-for="(rightButton, index) in rightButtons"
        :key="index"
        :style="{ color: rightButton.color }"
        v-show="rightButton.title"
        @click="rightButton.action"
        class="right-button"
      >
        {{ rightButton.title }}
      </p>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "NavigationBar",
  components: {},
  props: {
    leftButtons: {
      type: Array,
      required: false,
      default: new Array()
    },
    rightButtons: {
      type: Array,
      required: false,
      default: new Array()
    },
    title: {
      type: String,
      required: false
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    goBack() {
      router.back();
    }
  },
  created() {}
};
</script>

<style scoped>
.navigation-bar {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
  align-items: center;
  min-height: 2em;
}
.back-container {
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
  width: fit-content;
}
.arrow-icon {
  font-size: 2em;
}
.navigation-title {
  margin: 0;
  font-size: 1.3em;
}
.left-icons-container {
  display: flex;
  padding: 0.2em;
  gap: 1em;
}
.left-icon {
  cursor: pointer;
}
.left-button {
  cursor: pointer;
  margin: 0;
}
.right-icons-container {
  display: flex;
  padding: 0.2em;
  gap: 1em;
}
.right-icon {
  cursor: pointer;
}
.right-button {
  cursor: pointer;
  margin: 0;
}
</style>
