import { createI18n } from "vue-i18n";
const userLocale = localStorage.getItem('locale') || 'en';
import en from '@/locales/en.json';
import es from '@/locales/es.json';
import ar from '@/locales/ar.json';

const messages = {
  en,
  es,
  ar
};

const i18n = createI18n({
  locale: userLocale, 
  fallbackLocale: "en", 
  messages
});

export default i18n;
