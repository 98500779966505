import { createRouter, createWebHistory } from "vue-router";
import Landing from "@/views/Landing.vue";
import Home from "@/views/Home.vue";
import Details from "@/views/Details.vue";
import Map from "@/views/Map.vue";
import UserProfile from "@/views/UserProfile.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import Login from "@/views/Login.vue";
import NotFound from "@/views/NotFound.vue";
import ContactUs from "@/views/ContactUs";
import TermsAndConditions from "@/views/TermsAndConditions.vue";
import AddFriend from "@/views/AddFriend.vue";
import MyProfile from "@/views/MyProfile.vue";
import Settings from "@/views/Settings.vue";
import Chat from "@/views/Chat.vue";
import Expenses from "@/views/Expenses.vue";

const routes = [
  {
    path: "/",
    component: Landing
  },
  {
    path: "/home/:id?",
    component: Home
  },
  {
    path: "/profile/:id",
    component: UserProfile
  },
  {
    path: "/myprofile",
    component: MyProfile
  },
  {
    path: "/settings",
    component: Settings
  },
  {
    path: "/details/:id",
    component: Details
  },
  {
    path: "/map/:id",
    component: Map
  },
  {
    path: "/chat/:id",
    component: Chat
  },
  {
    path: "/expenses/:id",
    component: Expenses
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "/privacypolicy",
    component: PrivacyPolicy
  },
  {
    path: "/termsandconditions",
    component: TermsAndConditions
  },
  {
    path: "/contactus",
    component: ContactUs
  },
  {
    path: "/add/:id",
    component: AddFriend
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFound // Define this component to show your custom 404 page
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
