import axios from "axios";

class UserRepository {
  static async googleSignIn(code) {
    const response = await axios.post("auth/google/callback", {
      idToken: code,
      code: code
    });
    const user = response?.data?.data;
    localStorage.setItem(
      "loggedInUser",
      JSON.stringify({
        _id: user._id,
        displayName: user.displayName,
        currentTrip: user.currentTrip,
        email: user.email,
        qrCode: user.qrCode
      })
    );
  }
  static async appleSignIn(idToken, code) {
    const response = await axios.post("auth/appleWeb", {
      idToken: idToken,
      code: code
    });
    const user = response?.data?.data;
    localStorage.setItem(
      "loggedInUser",
      JSON.stringify({
        _id: user._id,
        displayName: user.displayName,
        currentTrip: user.currentTrip,
        email: user.email,
        qrCode: user.qrCode
      })
    );
  }
  
  static async deactivate() {
    await axios.get("auth/deactivate");
  }
  static async fetchUser(id) {
    const response = await axios.get("profile/" + id);
    return response?.data?.data;
  }
  static async fetchMyProfile() {
    const response = await axios.get("profile");
    return response?.data?.data;
  }
  static async fetchConnections() {
    const response = await axios.get("connection");
    return response?.data?.data;
  }
  static async fetchConnectionWithQRCode(qrCode) {
    const response = await axios.get("connection/qrCode/", { params: { qrCode: qrCode } });
    return response?.data?.data;
  }
  static async addConnection(user) {
    await axios.post("connection", user);
  }
  static async removeConnection(user) {
    await axios.delete("connection", { data: user });
  }
}

export default UserRepository;
