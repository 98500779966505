<template>
  <MainScreen
    :isLoading="isLoading"
    :showBackButton="true"
    :title="$i18n.t('preferences')"
  >
    <div class="settings-container" v-if="!isLoading">
      <p class="section-title">{{ $i18n.t("privacy") }}</p>
      <div class="preferences-list-container">
        <ListItem
          key="privacy-policy"
          :title="$i18n.t('privacy-policy')"
          :withSeparator="true"
          :onCLick="goToPrivacyPolicy"
        />
        <ListItem
          key="terms-and-conditions"
          :title="$i18n.t('terms-and-conditions')"
          :withSeparator="true"
          :onCLick="goToTermsAndConditions"
        />
        <ListItem
          key="contact-us"
          :title="$i18n.t('contact-us')"
          :withSeparator="false"
          :onCLick="goToContactUs"
        />
      </div>

      <p class="section-title">{{ $i18n.t("preferences") }}</p>
      <div class="preferences-list-container">
        <ListItem
          key="privacy-policy"
          :title="$i18n.t('auto-select-users-title')"
          :description="$i18n.t('auto-select-users-description')"
          :withSeparator="false"
          :withArrow="false"
          :withCheckbox="true"
          v-model:isOn="preferences.shouldAutoSelectUsersInExpense"
          @checkboxChanged="checkboxChanged"
        />
      </div>

      <p class="section-title">{{ $i18n.t("account") }}</p>
      <div class="preferences-list-container">
        <ListItem
          key="deactivate"
          :title="$i18n.t('deactivate')"
          :withSeparator="false"
          :withArrow="false"
          onCLick="deactivate"
        />
      </div>
    </div>
  </MainScreen>
</template>

<script>
import MainScreen from "@/views/components/MainScreen.vue";
import PreferenceRepository from "@/repositories/PreferenceRepository";
import UserRepository from "@/repositories/UserRepository";
import ListItem from "@/views/components/ListItem.vue";

import router from "@/router";

export default {
  name: "SettingsScreen",
  components: { MainScreen, ListItem },
  props: {},
  data() {
    return {
      user: JSON.parse(localStorage.getItem("loggedInUser")),
      isLoading: true,
      preferences: undefined
    };
  },
  methods: {
    goToPrivacyPolicy() {
      router.push("privacypolicy");
    },
    goToTermsAndConditions() {
      router.push("termsandconditions");
    },
    goToContactUs() {
      router.push("contactus");
    },
    async deactivate() {
      const confirmed = window.confirm(this.$i18n.t("confirm-deactivate"));
      if (confirmed) {
        try {
          await UserRepository.deactivate();
          router.replace("/login");
        } catch (error) {
          alert(error);
        }
      }
    },
    checkboxChanged() {
      this.savePreferences();
    },
    async savePreferences() {
      await PreferenceRepository.updatePreferences(this.preferences);
    }
  },
  async created() {
    this.isLoading = true;
    this.preferences = await PreferenceRepository.fetchPreferences();
    this.isLoading = false;
  }
};
</script>

<style scoped>
.settings-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.section-title {
  font-size: 1em;
  margin-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  margin-bottom: 0;
}
.preferences-list-container {
  border-radius: 1em;
  background-color: var(--background-secondary);
  margin: 1em;
  overflow: hidden;
}
</style>
