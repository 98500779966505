import axios from "axios";
class ExpensesRepository {
  static async fetch(tripID) {
    const response = await axios.get("expense", {
      params: { tripID: tripID }
    });
    return response?.data?.data;
  }
  static async addExpense(expense) {
    const response = await axios.post("expense", expense);
    return response?.data?.data;
  }
  static async editExpense(expense) {
    const response = await axios.put("expense", expense);
    return response?.data?.data;
  }
}

export default ExpensesRepository;
