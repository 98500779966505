import axios from "axios";
class MessagesRepository {
    static async fetch(tripID) {
        const response = await axios.get(
          "message", { params: { tripID: tripID } }
        );
        return response?.data?.data;
    }
    static async send(tripID, text) {
        const response = await axios.post(
          "message",  { tripID: tripID, text: text, type: "text" }
        );
        return response?.data?.data;
      }
}

export default MessagesRepository;
