import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import GoogleSignInPlugin from "vue3-google-signin";
import i18n from "./i18n";
import VueAppleLogin from "vue-apple-login";
import VueCookies from "vue-cookies";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const app = createApp(App);
app.use(VueCookies, { expire: "7d" });
app.component('PulseLoader', PulseLoader);
app.use(i18n);
app.use(router);
app.use(GoogleSignInPlugin, {
  clientId: `${process.env.VUE_APP_GOOGLE_CLIENT_ID}`
});

app.use(VueAppleLogin, {
  clientId: "com.noursandid.KowanderWeb",
  scope: "name email",
  redirectURI: "https://kowander.com/login",
  state: "state",
  usePopup: true
});

axios.defaults.baseURL = `${process.env.VUE_APP_BASE_URL}`;
axios.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "App-Platform": "web",
  "App-Version": "9.0.0",
  "App-Language": navigator.language
};

axios.interceptors.response.use(
  async (config) => {
    return config;
  },
  (error) => {
    if (error?.response?.status === 403) {
      localStorage.removeItem("loggedInUser");
      router.replace("/login");
    }
    return Promise.resolve();
  }
);

axios.defaults.withCredentials = true;
app.config.globalProperties.$axios = axios;
app.mount("#app");
