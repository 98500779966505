<!-- src/components/NotFoundComponent.vue -->

<template>
  <div class="not-found">
    <img alt="Kowander logo" src="../assets/logo.png" class="logo" />
    <h1>404</h1>
    <p>Sorry, the page you are looking for cannot be found.</p>
  </div>
</template>

<script>
export default {
  name: "NotFoundComponent"
};
</script>

<style scoped>
.logo {
  margin: 0;
  margin-top: 10px;
  max-height: 200px;
}
.not-found {
  font-family: Arial, sans-serif;
  text-align: center;
  padding-top: 15%;
}

.not-found h1 {
  font-size: 60px;
  margin-bottom: 15px;
}

.not-found p {
  font-size: 20px;
}
</style>
