<template>
  <div class="login-container">
    <div class="login-box">
      <img alt="Kowander logo" src="../assets/logo.png" class="logo" />
      <p class="title">Welcome!</p>

      <div class="social-login-buttons">
        <GoogleSignInButton
          :autoSelect="true"
          :oneTap="true"
          stateCookieDomain="kowander.com"
          type="standard"
          size="large"
          text="signin_with"
          shape="pill"
          width="300"
          class="login-button"
          context="signin"
          @success="handleGoogleLoginSuccess"
          @error="handleLoginError"
        ></GoogleSignInButton>

        <vue-apple-login
          class="login-button"
          :onSuccess="handleAppleSignInSuccess"
          :onFailure="handleLoginError"
        />
      </div>

      <p class="privacy-terms-text">
        {{ $i18n.t("accept-terms") }}
        <a href="/privacypolicy">{{ $i18n.t("privacy-policy") }}</a>
        {{ $i18n.t("and") }}
        <a href="/termsandconditions">{{ $i18n.t("terms-and-conditions") }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import { GoogleSignInButton } from "vue3-google-signin";
import UserRepository from "@/repositories/UserRepository";
import router from "@/router";

export default {
  name: "LoginScreen",
  components: { GoogleSignInButton },
  data() {
    return {};
  },
  methods: {
    async handleGoogleLoginSuccess(response) {
      try {
        await UserRepository.googleSignIn(response?.credential);
        router.push("/home");
      } catch (error) {
        alert(error);
      }
    },
    handleLoginError() {},
    async handleAppleSignInSuccess(response) {
      try {
        await UserRepository.appleSignIn(
          undefined,
          response.authorization.code
        );
        router.push("/home");
      } catch (error) {
        alert(error);
      }
    }
  },
  created() {
    if (
      localStorage.getItem("loggedInUser") !== null &&
      localStorage.getItem("loggedInUser") !== undefined
    ) {
      router.push("/home");
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
}

.login-box {
  background-color: var(--primary-lower);
  border-radius: 1.5em;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  padding-bottom: 1rem;
  text-align: center;
  width: 20rem;
  min-width: 20rem;
  max-width: 20rem;
}

.logo {
  height: 8rem;
  margin-bottom: 0;
}

.title {
  font-family: "BukhariScript", sans-serif;
  font-size: 2rem;
  color: var(--primary);
  margin-bottom: 1.5rem;
  margin-top: 0;
}

.social-login-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 300px;
  height: 50px;
  border-radius: 1.5em;
  overflow: hidden;
}

.social-icon {
  width: 20px;
  margin-right: 0.5rem;
}

/* Signup Text */
.privacy-terms-text {
  margin-top: 2rem;
  margin-bottom: 0;
}

.privacy-terms-text a {
  color: var(--primary);
  text-decoration: none;
  font-weight: bold;
}

.privacy-terms-text a:hover {
  text-decoration: underline;
}
</style>
