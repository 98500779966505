<template>
  <div class="modal-background" v-if="isVisible" @click="closeModal()">
    <div class="modal-container" @click.stop>
      <NavigationBar
        :title="$i18n.t('qr-code-scanner')"
        :rightButtons="[{ title: $i18n.t('cancel'), action: closeModal }]"
      />
      <div class="modal-content">
        <div>
          <QrcodeStream
            @detect="qrCodeDetected"
            @init="onInit"
            @error="onError"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import NavigationBar from "@/views/components/NavigationBar.vue";
import UserRepository from "@/repositories/UserRepository";
import router from "@/router";

export default {
  components: {
    QrcodeStream,
    NavigationBar
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      errorMessage: null
    };
  },
  methods: {
    async qrCodeDetected(qrCode) {
      console.log("QR Code Decoded:", qrCode);
      if (qrCode && qrCode.length && qrCode[0]) {
        const code = qrCode[0]?.rawValue;
        console.log(code);
        const user = await UserRepository.fetchConnectionWithQRCode(code);
        router.push("profile/" + user._id);
      }
    },
    onError(error) {
      console.error("QR Scanning Error:", error);
    },
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  overflow: auto;
  z-index: 1000;
}

.modal-container {
  background-color: var(--background-secondary);
  border-radius: 1em;
  width: 70dvw;
  max-width: 60em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin: auto;
  display: flex;
  flex-direction: column;
  max-height: 90dvh;
}

.modal-content {
  overflow: auto;
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
}
</style>
