<template>
  <div class="modal-background" v-if="isVisible" @click="closeModal()">
    <form
      @submit.prevent="saveChanges"
      ref="form"
      class="modal-container"
      @click.stop
    >
      <NavigationBar
        :title="$i18n.t('add_expense')"
        :leftButtons="[{ title: $i18n.t('cancel'), action: closeModal }]"
        :rightButtons="[
          {
            title: $i18n.t('save'),
            color: 'var(--primary)',
            action: () => {
              const form = this.$refs.form;
              if (form.checkValidity()) {
                form.requestSubmit();
              } else {
                form.reportValidity();
              }
            }
          }
        ]"
      />
      <PulseLoader v-if="isLoading" size="1em" class="loader" />
      <div class="modal-content" v-if="!isLoading">
        <p class="section-title">{{ $i18n.t("details") }}</p>
        <div class="expenses-list-container">
          <div class="form-group">
            <label>{{ $i18n.t("trip") }}</label>
            <input :required="true" type="text" v-model="trip.name" disabled />
          </div>
          <div class="form-group">
            <label>{{ $i18n.t("title") }}</label>
            <input
              :required="true"
              type="text"
              v-model="expense.title"
              :placeholder="$i18n.t('title')"
            />
          </div>
          <div class="form-group">
            <label>{{ $i18n.t("amount") }}</label>
            <input
              :required="true"
              type="decimal"
              v-model="expense.amount"
              placeholder="0"
            />
          </div>
          <div class="form-group">
            <label>{{ $i18n.t("paid") }}</label>
            <select v-model="paid">
              <option
                v-for="share in shares"
                :key="share.userID"
                :value="share"
              >
                {{ share.displayName }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>{{ $i18n.t("details") }}</label>
            <textarea v-model="expense.description"></textarea>
          </div>
        </div>
        <p class="section-title">{{ $i18n.t("shares") }}</p>
        <div class="expenses-list-container">
          <div v-for="share in shares" :key="share.userID" class="form-group">
            <div class="share-container">
              <input
                type="number"
                v-model="share.share"
                :disabled="!share.isOn"
                class="share-input"
                required
              />
              <p class="for-label">
                {{ $i18n.t("for", { user: share.displayName }) }}
              </p>
              <label>
                <input
                  type="checkbox"
                  v-model="share.isOn"
                  :disabled="share.userID === paid.userID"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import NavigationBar from "@/views/components/NavigationBar.vue";
import ExpensesRepository from "@/repositories/ExpensesRepository";
import PreferenceRepository from "@/repositories/PreferenceRepository";

export default {
  name: "AddTravelersModal",
  components: { NavigationBar },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    initialTrip: {
      type: Object,
      required: true
    },
    initialExpense: {
      type: Object,
      default: undefined,
      required: false
    }
  },
  data() {
    return {
      trip: { ...this.initialTrip },
      users: [...this.initialTrip.users],
      connections: [],
      expense: { ...this.initialExpense } ?? {},
      paid: undefined,
      currentUser: JSON.parse(localStorage.getItem("loggedInUser")),
      shares: undefined,
      isLoading: true,
      preferences: undefined
    };
  },
  watch: {
    paid: function (newValue) {
      this.shares.map((share) => {
        if (share.userID == newValue.userID) {
          share.isOn = true;
        }
        return share;
      });
    }
  },
  async created() {
    await this.fetchPreferences();
    this.shares = this.travelerNamesAndIDs;
    if (this.initialExpense) {
      this.paid = this.shares.find((share) => share.didPay);
    } else {
      this.paid = this.shares.find(
        (share) => share.userID === this.currentUser?._id
      );
    }
  },
  computed: {
    travelers() {
      return [...this.trip.users, ...this.trip.owners];
    },
    travelerNamesAndIDs() {
      return [...this.trip.users, ...this.trip.owners].map((user) => {
        if (this.initialExpense) {
          const share = this.initialExpense.shares.find(
            (share) => share.user._id === user._id
          );
          if (share) {
            return {
              displayName:
                share.user._id === this.currentUser._id
                  ? this.$i18n.t("you")
                  : share.user.displayName,
              _id: share._id,
              userID: share.user._id,
              share: share.share,
              didPay: share.didPay,
              isOn: true
            };
          } else {
            return {
              displayName:
                user._id === this.currentUser._id
                  ? this.$i18n.t("you")
                  : user.displayName,
              userID: user._id,
              didPay: true,
              share: 1,
              isOn: false
            };
          }
        } else {
          return {
            displayName:
              user._id === this.currentUser._id
                ? this.$i18n.t("you")
                : user.displayName,
            userID: user._id,
            didPay: true,
            share: 1,
            isOn:
              user._id === this.currentUser._id
                ? true
                : this.preferences.shouldAutoSelectUsersInExpense
          };
        }
      });
    }
  },
  methods: {
    closeModal() {
      const confirmed = window.confirm(
        this.$i18n.t("confirm-discard-expenses-changes")
      );
      if (confirmed) {
        this.$emit("close");
      }
    },
    async saveChanges() {
      if (this.trip.name) {
        const shares = this.shares
          .filter((traveler) => traveler.isOn)
          .map((traveler) => ({
            _id: traveler._id,
            user: this.travelers.find((user) => user._id === traveler.userID),
            share: parseFloat(traveler.share) ?? 1,
            didPay: traveler.userID === this.paid.userID
          }));

        try {
          if (this.expense && this.expense._id) {
            // Edit existing expense
            await ExpensesRepository.editExpense({
              _id: this.expense._id,
              trip: this.trip,
              shares,
              title: this.expense.title,
              description: this.expense.description,
              amount: parseFloat(this.expense.amount) || 0
            });
          } else {
            await ExpensesRepository.addExpense({
              _id: undefined,
              trip: this.trip,
              shares,
              title: this.expense.title,
              description: this.expense.description,
              amount: parseFloat(this.expense.amount) || 0
            });
          }
          this.$emit("reload");
          this.$emit("close");
        } catch (error) {
          console.error("Error saving expense:", error);
        }
      } else {
        alert(this.$i18n.t("please-fill-out-the-fields"));
      }
    },
    async fetchPreferences() {
      this.isLoading = true;
      this.preferences = await PreferenceRepository.fetchPreferences();
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  overflow: hidden;
  z-index: 1000;
}

.modal-container {
  background-color: var(--background-secondary);
  border-radius: 1em;
  width: 90dvw;
  max-width: 90em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin: auto;
  display: flex;
  flex-direction: column;
  min-height: 80%;
  max-height: 90dvh;
}

.modal-content {
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
  overflow: auto;
}

.section-title {
  padding-left: 1em;
  font-size: 1em;
  margin-bottom: 0;
}

.expenses-list-container {
  border-radius: 1em;
  background-color: var(--background-secondary2);
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em;
  padding-top: 0.1em;
  padding-right: 0em;
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  margin-right: 2em;
}

.form-group label,
.form-group select {
  flex: 1;
  margin-bottom: 0.5em;
  margin-top: 1em;
}
.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.5em;
  border-radius: 0.5em;
  border: none;
  font-size: 1em;
}

.share-container {
  display: flex;
  align-items: center;
  gap: 1em;
}

.share-input {
  padding: 0.5em;
  width: 5em;
}

.for-label {
  width: fit-content;
  text-wrap: nowrap;
}

.loader {
  align-self: center;
  justify-self: center;
}
</style>
