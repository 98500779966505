import axios from "axios";

class TripsRepository {
  static async fetchTrips() {
    const response = await axios.get("trip");
    return response?.data?.data;
  }
  static async fetchTrip(id) {
    const response = await axios.get("trip/" + id);
    return response?.data?.data;
  }
  static async saveTrip(trip) {
    const response = await axios.put("trip/", trip);
    return response?.data?.data;
  }
  static async addTrip(trip) {
    const response = await axios.post("trip/", trip);
    return response?.data?.data;
  }
  static async deleteTrip(trip) {
    const reponse = await axios.delete("trip/", { data: trip });
    return reponse?.data?.data;
  }
}

export default TripsRepository;
